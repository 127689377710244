import React from "react";
import './App.css';
import { useNavigate } from "react-router-dom";

import { ImageButton, SecondaryColorButtonPill } from './Components/buttons';

function App() {
  const navigate = useNavigate();

  const handleClickLogin = async () => {
    navigate("login");
  };

  const handleClickSignUp = async () => {
    navigate("signup");
  };

  return (
    <div className="flex flex-col items-center bg-primary h-screen ">
        <div className=" basis-1/2 flex items-center ">
          <ImageButton to="/" img="https://aidstorage1.blob.core.windows.net/images/datafresh_logo.png"></ImageButton>
        </div>
        <div className=" basis-1/2 flex ">
          <div className=" flex flex-col gap-14">
            <SecondaryColorButtonPill onClick={handleClickLogin}>Login</SecondaryColorButtonPill>
            <SecondaryColorButtonPill onClick={handleClickSignUp}>Sign Up</SecondaryColorButtonPill>
          </div> 
        </div>
    </div>
  );
}

export default App;
