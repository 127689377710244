import React, { useState } from "react";
import "react-router-dom";
import SignUpFirstScreen from "./signUpFirstScreen";
import SignUpSecondScreen from "./signUpSecondScreen";

function MultiStepForm() {
    const [form, setForm] = useState({
        step: 1,
        accountType: "", // individual or license
        firstName: "",
        lastName: "",
        password: "",
        email: "",
        phoneNumber: "",
        company: "",
        sid: ""
    });

    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.id]: event.target.value,
        });
    };

    const nextStep = () => {
        setForm({
            ...form,
            step: form.step + 1,
        });
    };

    const prevStep = () => {
        setForm({
            ...form,
            step: form.step - 1,
        });
    };

    switch (form.step) {
        case 1:
            return (
                <SignUpFirstScreen
                    handleChange={handleChange}
                    nextStep={nextStep}
                    form={form}
                />
            );
        case 2:
            return (
                <SignUpSecondScreen
                    handleChange={handleChange}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    form={form}
                />
            );
        default:
        // do nothing
    }
}

export default MultiStepForm;
