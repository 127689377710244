import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { SecondaryColorButtonPill, TextButton } from "../../../Components/buttons";
import { SingleLineTextInput } from "../../../Components/input";



function SignUpSecondScreen(props) {
    const [requiredPhoneNumber, setRequiredPhoneNumber] = useState(true);
    const [requiredCompany, setRequiredCompany] = useState(true);
    const navigate = useNavigate();

    const handleClickSignUp = async () => {
        let canAdvance = true;
        if (props.form.phoneNumber === "") {
            setRequiredPhoneNumber(false);
            canAdvance = false;
        } else {
            setRequiredPhoneNumber(true);
        }

        if (props.form.company === "") {
            setRequiredCompany(false);
            canAdvance = false;
        } else {
            setRequiredCompany(true);
        }

        if (canAdvance) {
            // putUserDataAPI(props.form);
            navigate("/dashboard");
        }
    };

    const handleClickBack = async () => {
        props.prevStep();
    };

    return (
        <div className="flex flex-col items-center bg-primary h-screen ">
            <div className=" basis-1/12 flex items-end w-11/12 sm:w-1/2">
                <TextButton onClick={handleClickBack} >Back</TextButton>
            </div>
            <div className="flex flex-col gap-10 justify-center items-center h-screen basis-8/12">
                <SingleLineTextInput
                    id="phoneNumber"
                    type="text"
                    placeholder="phone number"
                    value={props.form.phoneNumber}
                    onChange={props.handleChange}
                    valid={true}
                    required={requiredPhoneNumber}
                />
                <SingleLineTextInput
                    id="company"
                    type="text"
                    placeholder="company"
                    value={props.form.company}
                    onChange={props.handleChange}
                    valid={true}
                    required={requiredCompany}
                />
            </div>
            <div className="flex basis-1/12 items-start">
                <SecondaryColorButtonPill onClick={handleClickSignUp}>Sign Up!</SecondaryColorButtonPill>
            </div>
        </div>
    );
}

export default SignUpSecondScreen;
