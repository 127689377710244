import React from "react";
import { Link } from "react-router-dom";

export const SecondaryColorButtonPill = ({ children, ...rest }) => (
    <button
        {...rest}
        className="flex justify-center w-32 py-4 px-5 font-semibold rounded-full text-white bg-secondary hover:bg-secondary_light drop-shadow-lg"
    >
        {children}
    </button>
);

export const ImageButton = ({ to, img }) => (
    <Link to={to}>
        <img
            className="sm:scale-50"
            src={img}
            alt="ImageButton"
        />
    </Link>
);

export const TextButton = ({ children, ...rest }) => (
    <button
        {...rest}
        className="flex font-semibold text-white hover:first-letter:bg-white"
    >
        {children}
    </button>
);
