import React from "react";

function DashboardHomeScreen() {
    return (
        <div className="flex flex-col items-center py-2 px-4">
          <div className="container flex justify-center flex-col mt-2 mb-20">
            Coming Soon!
          </div>
         
        </div>
      );
}

export default DashboardHomeScreen;

