import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { SecondaryColorButtonPill, TextButton } from "../../../Components/buttons";
import { SingleLineTextInput } from "../../../Components/input";


function SignUpFirstScreen(props) {
    const [valid, setValid] = useState(true);
    const [requiredFirstName, setRequiredFirstName] = useState(true);
    const [requiredLastName, setRequiredLastName] = useState(true);
    const [requiredEmail, setRequiredEmail] = useState(true);
    const [requiredPassword, setRequiredPassword] = useState(true);

    const navigate = useNavigate();

    const handleClickNext = async () => {
        let canAdvance = true;
        // checks if fields are filled, we can also check via regex in the future for input to match desired
        // format
        if (props.form.firstName === "") {
            setRequiredFirstName(false);
            canAdvance = false;
        } else {
            setRequiredFirstName(true);
        }

        if (props.form.lastName === "") {
            setRequiredLastName(false);
            canAdvance = false;
        } else {
            setRequiredLastName(true);
        }

        if (props.form.email === "") {
            setRequiredEmail(false);
            canAdvance = false;
        } else {
            setRequiredEmail(true);
        }

        if (props.form.password === "") {
            setRequiredPassword(false);
            canAdvance = false;
        } else {
            setRequiredPassword(true);
        }

        // if forms fields are not empty, check if valid with backend
        // let isAuth = await signUpAPI(
        //     props.form.userID,
        //     props.form.password,
        //     props.form.email,
        //     props.form.firstName,
        //     props.form.lastName
        // );

        // if (isAuth && canAdvance)
        if (canAdvance) {
            props.nextStep();
        } else {
            setValid(false);
        }
    };

    const handleClickCancel = async () => {
        navigate("/");
    };

    return (
        <div className="flex flex-col items-center bg-primary h-screen ">
            <div className=" basis-1/12 flex items-end w-11/12 sm:w-1/2">
                <TextButton onClick={handleClickCancel} >Cancel</TextButton>
            </div>

            <div className="flex flex-col gap-10 justify-center items-center h-screen basis-8/12">
                <SingleLineTextInput
                    id="firstName"
                    type="text"
                    placeholder="first name"
                    value={props.form.firstName}
                    onChange={props.handleChange}
                    valid={true}
                    required={requiredFirstName}
                />
                <SingleLineTextInput
                    id="lastName"
                    type="text"
                    placeholder="last name"
                    value={props.form.lastName}
                    onChange={props.handleChange}
                    valid={true}
                    required={requiredLastName}
                />
                <SingleLineTextInput
                    id="email"
                    type="email"
                    placeholder="email"
                    value={props.form.email}
                    onChange={props.handleChange}
                    valid={true}
                    required={requiredEmail}
                />
                <SingleLineTextInput
                    id="password"
                    type="password"
                    placeholder="password"
                    value={props.form.password}
                    onChange={props.handleChange}
                    valid={valid}
                    required={requiredPassword}
                /> 
            </div>
            <div className="flex basis-1/12 items-start">
                <SecondaryColorButtonPill onClick={handleClickNext}>Next</SecondaryColorButtonPill>
            </div>
        </div>
    );
}

export default SignUpFirstScreen;
