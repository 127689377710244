import React from "react";
import classNames from "classnames";

export const SingleLineTextInput = ({
    id,
    type,
    placeholder,
    onChange,
    value,
    valid,
    required,
}) => {
    return (
        <input
            className={classNames(
                required && valid
                    ? "px-3 py-2 w-72 bg-white border shadow-sm border-white focus:outline-2 focus:border-primaryDarkGrey rounded-lg sm:text-sm "
                    : "px-3 py-2 w-72 bg-white shadow-sm border-red-600 border-2 focus:outline-2 focus:border-red-600 focus:outline-red-600 rounded-lg sm:text-sm "
            )}
            id={id}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
        ></input>
    );
};